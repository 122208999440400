import type {
  BigConstituency,
  Municipality,
  Region,
  SmallConstituency,
  UserPlace,
} from '~/typesManual/vaa_api/areas'
import type { Education, Profession } from '~/typesManual/vaa_api/candidate'
import type { Election, Rule } from '~/typesManual/vaa_api/election'
import type { LocationQuery } from 'vue-router'
import { z } from 'zod'

export type VaaState = {
  allowedIds: Set<number>
  municipalities: Municipality[]
  regions: Region[]
  customLogoUrl?: string
  bigConstituencies: BigConstituency[]
  smallConstituencies: SmallConstituency[]
  elections: Election[]
  professions: Profession[]
  educations: Education[]
  bigConstituencyNameFromMunicipality: Record<number, string>
  smallConstituencyFromMunicipality: Record<number, string[]>
  ages: {
    ID: string
    Name: string
  }[]
  genders: {
    ID: string
    Name: string
  }[]
  snMunicipalities: Municipality[]
  hasLoaded: boolean
}
const EP_GROUP = { ID: 2, Name: '' }

export const useVaaStore = defineStore('vaa', () => {
  const nuxtApp = useNuxtApp()
  const siteConfig = useRuntimeConfig().public.site
  const route = useRoute()
  const localeOverride = useLocaleOverride()
  const { usermeta } = useVaa()

  // We save the allowedIds to state so they're kept on client navigation.
  // They won't be saved if the user refreshes, but since this will be used from an iframe,
  // the user can only navigate by clicking links
  const allowedIds = getAllowedIdsFromQueryParameters(route.query)

  /*
  // State
  */
  const elections = ref<Election[] | undefined>(undefined)
  const hasLoaded = ref<boolean>(false)
  const municipalities = ref<Municipality[]>([])
  const snMunicipalities = ref<Municipality[]>([
    // Kommuner for sjællandske medier
    { ID: 1, Name: 'Albertslund Kommune', ID_KMD: 165, ID_Region: 1 },
    { ID: 2, Name: 'Allerød Kommune', ID_KMD: 201, ID_Region: 1 },
    { ID: 8, Name: 'Ballerup Kommune', ID_KMD: 151, ID_Region: 1 },
    { ID: 25, Name: 'Brøndby Kommune', ID_KMD: 153, ID_Region: 1 },
    { ID: 299, Name: 'Egedal Kommune', ID_KMD: 240, ID_Region: 1 },
    { ID: 304, Name: 'Faxe Kommune', ID_KMD: 320, ID_Region: 2 },
    { ID: 47, Name: 'Fredensborg Kommune', ID_KMD: 210, ID_Region: 1 },
    {
      ID: 51,
      Name: 'Frederikssund Kommune',
      ID_KMD: 250,
      ID_Region: 1,
    },
    { ID: 296, Name: 'Furesø Kommune', ID_KMD: 190, ID_Region: 1 },
    { ID: 57, Name: 'Gentofte Kommune', ID_KMD: 157, ID_Region: 1 },
    { ID: 60, Name: 'Gladsaxe Kommune', ID_KMD: 159, ID_Region: 1 },
    { ID: 62, Name: 'Glostrup Kommune', ID_KMD: 161, ID_Region: 1 },
    { ID: 65, Name: 'Greve Kommune', ID_KMD: 253, ID_Region: 2 },
    { ID: 302, Name: 'Gribskov Kommune', ID_KMD: 270, ID_Region: 1 },
    {
      ID: 294,
      Name: 'Guldborgsund Kommune',
      ID_KMD: 376,
      ID_Region: 2,
    },
    { ID: 303, Name: 'Halsnæs Kommune', ID_KMD: 260, ID_Region: 1 },
    { ID: 84, Name: 'Helsingør Kommune', ID_KMD: 217, ID_Region: 1 },
    { ID: 85, Name: 'Herlev Kommune', ID_KMD: 163, ID_Region: 1 },
    { ID: 87, Name: 'Hillerød Kommune', ID_KMD: 219, ID_Region: 1 },
    { ID: 92, Name: 'Holbæk Kommune', ID_KMD: 316, ID_Region: 2 },
    { ID: 102, Name: 'Hvidovre Kommune', ID_KMD: 167, ID_Region: 1 },
    {
      ID: 104,
      Name: 'Høje-Taastrup Kommune',
      ID_KMD: 169,
      ID_Region: 1,
    },
    { ID: 109, Name: 'Hørsholm Kommune', ID_KMD: 223, ID_Region: 1 },
    { ID: 112, Name: 'Ishøj Kommune', ID_KMD: 183, ID_Region: 1 },
    { ID: 117, Name: 'Kalundborg Kommune', ID_KMD: 326, ID_Region: 2 },
    { ID: 125, Name: 'Køge Kommune', ID_KMD: 259, ID_Region: 2 },
    { ID: 130, Name: 'Lejre Kommune', ID_KMD: 350, ID_Region: 2 },
    { ID: 293, Name: 'Lolland Kommune', ID_KMD: 360, ID_Region: 2 },
    {
      ID: 134,
      Name: 'Lyngby-Taarbæk Kommune',
      ID_KMD: 173,
      ID_Region: 1,
    },
    { ID: 156, Name: 'Næstved Kommune', ID_KMD: 370, ID_Region: 2 },
    { ID: 292, Name: 'Odsherred Kommune', ID_KMD: 306, ID_Region: 2 },
    { ID: 176, Name: 'Ringsted Kommune', ID_KMD: 329, ID_Region: 2 },
    { ID: 178, Name: 'Roskilde Kommune', ID_KMD: 265, ID_Region: 2 },
    { ID: 295, Name: 'Rudersdal Kommune', ID_KMD: 230, ID_Region: 1 },
    { ID: 187, Name: 'Rødovre Kommune', ID_KMD: 175, ID_Region: 1 },
    { ID: 207, Name: 'Slagelse Kommune', ID_KMD: 330, ID_Region: 2 },
    { ID: 209, Name: 'Solrød Kommune', ID_KMD: 269, ID_Region: 2 },
    { ID: 210, Name: 'Sorø Kommune', ID_KMD: 340, ID_Region: 2 },
    { ID: 214, Name: 'Stevns Kommune', ID_KMD: 336, ID_Region: 2 },
    { ID: 305, Name: 'Vallensbæk Kommune', ID_KMD: 187, ID_Region: 1 },
    { ID: 260, Name: 'Vordingborg Kommune', ID_KMD: 390, ID_Region: 2 },
  ])
  const regions = ref<Region[]>([])
  const bigConstituencies = ref<BigConstituency[]>([])
  const smallConstituencies = ref<SmallConstituency[]>([])
  const professions = ref<Profession[]>([])
  const educations = ref<Education[]>([])
  const bigConstituencyNameFromMunicipality = ref<Record<number, string>>({
    // KMD_ID: name
    101: 'Københavns Storkreds',
    147: 'Københavns Storkreds',
    155: 'Københavns Storkreds',
    185: 'Københavns Storkreds',
    157: 'Københavns Omegns Storkreds',
    173: 'Københavns Omegns Storkreds',
    159: 'Københavns Omegns Storkreds',
    163: 'Københavns Omegns Storkreds',
    175: 'Københavns Omegns Storkreds',
    167: 'Københavns Omegns Storkreds',
    153: 'Københavns Omegns Storkreds',
    183: 'Københavns Omegns Storkreds',
    187: 'Københavns Omegns Storkreds',
    165: 'Københavns Omegns Storkreds',
    169: 'Københavns Omegns Storkreds',
    151: 'Københavns Omegns Storkreds',
    161: 'Københavns Omegns Storkreds',
    217: 'Nordsjællands Storkreds',
    210: 'Nordsjællands Storkreds',
    223: 'Nordsjællands Storkreds',
    219: 'Nordsjællands Storkreds',
    270: 'Nordsjællands Storkreds',
    250: 'Nordsjællands Storkreds',
    260: 'Nordsjællands Storkreds',
    190: 'Nordsjællands Storkreds',
    240: 'Nordsjællands Storkreds',
    201: 'Nordsjællands Storkreds',
    230: 'Nordsjællands Storkreds',
    400: 'Bornholms Storkreds',
    411: 'Bornholms Storkreds',
    360: 'Sjællands Storkreds',
    376: 'Sjællands Storkreds',
    390: 'Sjællands Storkreds',
    370: 'Sjællands Storkreds',
    320: 'Sjællands Storkreds',
    336: 'Sjællands Storkreds',
    259: 'Sjællands Storkreds',
    350: 'Sjællands Storkreds',
    253: 'Sjællands Storkreds',
    269: 'Sjællands Storkreds',
    265: 'Sjællands Storkreds',
    316: 'Sjællands Storkreds',
    306: 'Sjællands Storkreds',
    326: 'Sjællands Storkreds',
    329: 'Sjællands Storkreds',
    340: 'Sjællands Storkreds',
    330: 'Sjællands Storkreds',
    461: 'Fyns Storkreds',
    420: 'Fyns Storkreds',
    410: 'Fyns Storkreds',
    480: 'Fyns Storkreds',
    440: 'Fyns Storkreds',
    450: 'Fyns Storkreds',
    479: 'Fyns Storkreds',
    482: 'Fyns Storkreds',
    430: 'Fyns Storkreds',
    492: 'Fyns Storkreds',
    540: 'Sydjyllands Storkreds',
    580: 'Sydjyllands Storkreds',
    550: 'Sydjyllands Storkreds',
    561: 'Sydjyllands Storkreds',
    563: 'Sydjyllands Storkreds',
    573: 'Sydjyllands Storkreds',
    530: 'Sydjyllands Storkreds',
    575: 'Sydjyllands Storkreds',
    630: 'Sydjyllands Storkreds',
    607: 'Sydjyllands Storkreds',
    621: 'Sydjyllands Storkreds',
    510: 'Sydjyllands Storkreds',
    751: 'Østjyllands Storkreds',
    706: 'Østjyllands Storkreds',
    707: 'Østjyllands Storkreds',
    730: 'Østjyllands Storkreds',
    710: 'Østjyllands Storkreds',
    727: 'Østjyllands Storkreds',
    741: 'Østjyllands Storkreds',
    746: 'Østjyllands Storkreds',
    615: 'Østjyllands Storkreds',
    766: 'Østjyllands Storkreds',
    665: 'Vestjyllands Storkreds',
    671: 'Vestjyllands Storkreds',
    779: 'Vestjyllands Storkreds',
    791: 'Vestjyllands Storkreds',
    740: 'Vestjyllands Storkreds',
    756: 'Vestjyllands Storkreds',
    657: 'Vestjyllands Storkreds',
    661: 'Vestjyllands Storkreds',
    760: 'Vestjyllands Storkreds',
    813: 'Nordjyllands Storkreds',
    825: 'Nordjyllands Storkreds',
    860: 'Nordjyllands Storkreds',
    810: 'Nordjyllands Storkreds',
    849: 'Nordjyllands Storkreds',
    773: 'Nordjyllands Storkreds',
    787: 'Nordjyllands Storkreds',
    820: 'Nordjyllands Storkreds',
    840: 'Nordjyllands Storkreds',
    846: 'Nordjyllands Storkreds',
    851: 'Nordjyllands Storkreds',
  })

  const smallConstituencyFromMunicipality = ref<Record<number, string[]>>({
    // KMD_ID: [name1, name2, ...]
    101: [
      'Østerbro',
      'Sundbyvester',
      'Indre By',
      'Sundbyøster',
      'Nørrebro',
      'Bispebjerg',
      'Brønshøj',
      'Valby',
      'Vesterbro',
    ],
    147: ['Falkoner', 'Slots'],
    155: ['Tårnby'],
    185: ['Tårnby'],
    157: ['Gentofte'],
    173: ['Lyngby'],
    159: ['Gladsaxe'],
    163: ['Rødovre'],
    175: ['Rødovre'],
    167: ['Hvidovre'],
    153: ['Brøndby'],
    183: ['Brøndby'],
    187: ['Brøndby'],
    165: ['Taastrup'],
    169: ['Taastrup'],
    151: ['Ballerup'],
    161: ['Ballerup'],
    217: ['Helsingør'],
    210: ['Fredensborg'],
    223: ['Fredensborg'],
    219: ['Hillerød'],
    270: ['Hillerød'],
    250: ['Frederikssund'],
    260: ['Frederikssund'],
    190: ['Egedal'],
    240: ['Egedal'],
    201: ['Rudersdal'],
    230: ['Rudersdal'],
    400: ['Rønne', 'Aakirkeby'],
    411: ['Aakirkeby'],
    360: ['Lolland'],
    376: ['Guldborgsund'],
    390: ['Vordingborg'],
    370: ['Næstved'],
    320: ['Faxe'],
    336: ['Faxe'],
    259: ['Køge'],
    350: ['Køge'],
    253: ['Greve'],
    269: ['Greve'],
    265: ['Roskilde'],
    316: ['Holbæk'],
    306: ['Kalundborg'],
    326: ['Kalundborg'],
    329: ['Ringsted'],
    340: ['Ringsted'],
    330: ['Slagelse'],
    461: ['Odense Øst', 'Odense Vest', 'Odense Syd'],
    420: ['Assens'],
    410: ['Middelfart'],
    480: ['Middelfart'],
    440: ['Nyborg'],
    450: ['Nyborg'],
    479: ['Svendborg'],
    482: ['Svendborg'],
    430: ['Faaborg'],
    492: ['Faaborg'],
    540: ['Sønderborg'],
    580: ['Aabenraa'],
    550: ['Tønder'],
    561: ['Esbjerg By', 'Esbjerg Omegn'],
    563: ['Esbjerg By'],
    573: ['Varde'],
    530: ['Vejen'],
    575: ['Vejen'],
    630: ['Vejle Nord', 'Vejle Syd'],
    607: ['Fredericia'],
    621: ['Kolding Nord', 'Kolding Syd'],
    510: ['Haderslev'],
    751: ['Aarhus Syd', 'Aarhus Vest', 'Aarhus Nord', 'Aarhus Øst'],
    706: ['Djurs'],
    707: ['Djurs'],
    730: ['Randers Nord', 'Randers Syd'],
    710: ['Favrskov'],
    727: ['Skanderborg'],
    741: ['Skanderborg'],
    746: ['Skanderborg'],
    615: ['Horsens'],
    766: ['Hedensted'],
    665: ['Struer'],
    671: ['Struer'],
    779: ['Skive'],
    791: ['Viborg Vest', 'Viborg Øst'],
    740: ['Silkeborg Nord', 'Silkeborg Syd'],
    756: ['Ikast'],
    657: ['Herning Syd', 'Herning Nord'],
    661: ['Holstebro'],
    760: ['Ringkøbing'],
    813: ['Frederikshavn'],
    825: ['Frederikshavn'],
    860: ['Hjørring'],
    810: ['Brønderslev'],
    849: ['Brønderslev'],
    773: ['Thisted'],
    787: ['Thisted'],
    820: ['Himmerland'],
    840: ['Himmerland'],
    846: ['Mariagerfjord'],
    851: ['Aalborg Øst', 'Aalborg Vest', 'Aalborg Nord'],
  })
  /*
  // Actions
  */
  const loadAll = async () => {
    if (hasLoaded.value) {
      return
    }

    await Promise.all([
      loadMunicipalities(),
      loadRegions(),
      loadConstituencies(),
      loadElections(),
      loadCandidateOptions(),
    ])

    hasLoaded.value = true
  }

  const loadMunicipalities = async () => {
    if (siteConfig.identifier === 'FTSn22') {
      municipalities.value = snMunicipalities.value
      return
    }

    municipalities.value = await nuxtApp.$api.vaa.getMunicipalities()
  }

  const loadRegions = async () => {
    regions.value = await nuxtApp.$api.vaa.getRegions()
  }

  const loadConstituencies = async () => {
    const [big, small] = await Promise.allSettled([
      nuxtApp.$api.vaa.getConstituenciesBig(),
      nuxtApp.$api.vaa.getConstituenciesSmall(),
    ])

    if (big.status === 'fulfilled') {
      bigConstituencies.value = big.value
    }

    if (small.status === 'fulfilled') {
      smallConstituencies.value = small.value
    }
  }

  const loadElections = async () => {
    // Get all elections
    elections.value = (await nuxtApp.$api.vaa.getElections())
      .filter((election) => election?.FrontendUrlPrefix)
      .map((election) => {
        // Hack to translate election names to nynorsk
        if (localeOverride.value !== 'nnNO') {
          return election
        }

        return {
          ...election,
          Name: election.Name.replace('VALGET', 'VALET'),
        }
      })

    if (siteConfig.identifier === 'FTSn22') {
      elections.value.push({
        ID: 6,
        Name: 'Folketingsvalg 2022',
        IsActive: 1,
        CandidateKeyTopicAmount: 1,
        ActivateAutoCandidateKeyTopicTitles: 0,
        Rule: 'FT',
        Picture: null,
        Prefix: 'FTSn22',
        FrontendUrlPrefix: 'FTSn22',
        BallotByGroup: 'SmallConstituency',
        MatchByGroup: 'BigConstituency',
      })
    }

    elections.value = await Promise.all(
      elections.value.map(async (election) => ({
        valgomat: (await nuxtApp.$api.vaa.getValgomats(election.ID))?.[0],
        ...election,
      }))
    )
  }

  const loadCandidateOptions = async () => {
    const [responseProfessions, responseEducations] = await Promise.all([
      nuxtApp.$api.vaa.getProfessions(),
      nuxtApp.$api.vaa.getEducations(),
    ])

    professions.value = responseProfessions
    educations.value = responseEducations
  }

  const getElectionById = (id: number | string): Election | undefined => {
    return elections.value?.find(
      (election) =>
        election?.ID?.toString().toLowerCase() ===
          id?.toString().toLowerCase() ||
        election?.FrontendUrlPrefix?.toString().toLowerCase() ===
          id?.toString().toLowerCase()
    )
  }

  const getUserPlace = (rule: Rule): UserPlace | undefined => {
    switch (rule) {
      case 'FT':
        return usermeta.value?.bigConstituency
      case 'KV':
      case 'LV':
        return usermeta.value?.municipality
      case 'RV':
        return usermeta.value?.region
      case 'EP':
        return EP_GROUP
    }
  }

  const getUserSelectPlace = (rule: Rule): UserPlace | undefined => {
    switch (rule) {
      case 'FT':
        return usermeta.value?.municipality
      case 'KV':
      case 'LV':
        return usermeta.value?.municipality
      case 'RV':
        return usermeta.value?.region
      case 'EP':
        return EP_GROUP
    }
  }

  const getSelectOptions = (rule: Rule) => {
    let options = []
    switch (rule) {
      case 'FT':
        options = municipalities.value
        break
      case 'KV':
      case 'LV':
        options = municipalities.value
        break
      case 'RV':
        options = regions.value
        break
      case 'EP':
        options = [EP_GROUP]
        break
    }
    if (allowedIds.size == 0) {
      return options
    }
    return options.filter((option) => {
      return allowedIds.has(option.ID)
    })
  }

  const getActiveElections = computed(() => {
    return elections.value?.filter((election) => election.IsActive === 1) ?? []
  })

  const getInactiveElections = computed(() => {
    return elections.value?.filter((election) => election.IsActive === 0) ?? []
  })

  const getQuestions = async (
    electionId: number,
    valgomatId: number,
    groupId: number,
    extraTopicIds?: number[]
  ) => {
    const nuxtApp = useNuxtApp()

    const siteLocale = useRuntimeConfig().public.site.locale
    const localeOverride = useLocaleOverride()
    const useOptionalLocale =
      (localeOverride.value && localeOverride.value !== siteLocale) || undefined

    const questions = await nuxtApp.$api.vaa.getQuestions(
      electionId,
      valgomatId,
      groupId,
      useOptionalLocale,
      extraTopicIds
    )

    //const key = `${electionId}.${groupId}`

    return questions
    // TODO: Refactor to use proper types
    // return questions.map((question) => ({
    //   ...question,
    //   answer: this.usermeta?.answers[key]?.[question?.ID]?.answer || 0,
    //   important: this.usermeta?.answers[key]?.[question?.ID]?.important || 0,
    // }))
  }

  const getBigConstituencyNameFromMunicipality = (
    municipalityKMDID: number
  ) => {
    return bigConstituencyNameFromMunicipality.value[municipalityKMDID]
  }

  const getUserBallotGroup = (election: Election) => {
    if (election.Rule === 'EP') return EP_GROUP

    const groupTypeMap = {
      Municipality: 'municipality',
      Region: 'region',
      BigConstituency: 'bigConstituency',
      SmallConstituency: 'smallConstituency',
    } as const

    return usermeta.value?.[groupTypeMap[election.BallotByGroup]]
  }

  const getSmallConstituencyFromMunicipality = (municipalityKMDID: number) => {
    return smallConstituencyFromMunicipality.value[municipalityKMDID]
  }

  const getBigConstituencyFromName = (name: string) => {
    return bigConstituencies.value.find((constituency) =>
      constituency.Name.includes(name)
    )
  }

  const getSmallConstituencyFromName = (name: string) => {
    return smallConstituencies.value.find((constituency) =>
      constituency.Name.includes(name)
    )
  }

  const setUserMunicipality = (placeId?: number) => {
    if (!placeId) {
      usermeta.value = {
        ...usermeta.value,
        bigConstituency: undefined,
        municipality: undefined,
        smallConstituency: undefined,
      }
      return
    }

    const municipality = municipalities.value.find((mun) => mun.ID === placeId)
    if (!municipality) return

    usermeta.value = { ...usermeta.value, municipality: toRaw(municipality) }

    if (!municipality.ID_KMD) return

    const bigConstituencyName = getBigConstituencyNameFromMunicipality(
      municipality.ID_KMD
    )
    const bigConstituency = getBigConstituencyFromName(bigConstituencyName)

    // Get the first small constituency. The user can set a more specific small constituency in electionBallot.vue
    const [smallConstituencyName] = getSmallConstituencyFromMunicipality(
      municipality.ID_KMD
    )
    const smallConstituency = getSmallConstituencyFromName(
      smallConstituencyName
    )

    usermeta.value = {
      ...usermeta.value,
      bigConstituency,
      smallConstituency,
    }
  }

  const setUserPlace = async (placeId: number | undefined, rule: Rule) => {
    switch (rule) {
      case 'FT':
        setUserMunicipality(placeId)
        break
      case 'KV':
      case 'LV':
        setUserMunicipality(placeId)
        break
      case 'RV':
        const region = regions.value.find((region) => region.ID === placeId)

        usermeta.value = { ...usermeta.value, region }
        break
    }
  }

  return {
    loadAll,
    getElectionById,
    getUserPlace,
    getUserSelectPlace,
    getSelectOptions,
    getQuestions,
    getUserBallotGroup,
    getActiveElections,
    getInactiveElections,

    setUserPlace,

    allowedIds,
    municipalities: municipalities.value,
    regions: regions.value,
    bigConstituencies: bigConstituencies.value,
    customLogoUrl: getCustomLogoUrl(route.query),
    smallConstituencies: smallConstituencies.value,
    elections: elections.value,
    professions: professions.value,
    educations: educations.value,
    bigConstituencyNameFromMunicipality:
      bigConstituencyNameFromMunicipality.value,
    smallConstituencyFromMunicipality: smallConstituencyFromMunicipality.value,
    ages: [
      {
        ID: '18-29',
        Name: '18-29',
      },
      {
        ID: '30-39',
        Name: '30-39',
      },
      {
        ID: '40-49',
        Name: '40-49',
      },
      {
        ID: '50-59',
        Name: '50-59',
      },
      {
        ID: '60-99',
        Name: '60-99',
      },
    ],
    genders: [
      {
        ID: 'M',
        Name: 'M',
      },
      {
        ID: 'F',
        Name: 'F',
      },
      {
        ID: 'O',
        Name: 'O',
      },
    ],
    snMunicipalities: snMunicipalities.value,
    hasLoaded: hasLoaded.value,
  }

  // NOT IN USE DUE TO THE OLD ELECTIONS NOT WORKING the old elections use v1 endpoints and potentially also this to have left this in case it is needed again
  //     getGroupById: () => (groupId: number, electionRule: Rule) => {
  //       return this.getGroups(electionRule).find((group) => group.ID === groupId)
  //     },

  //     getGroups: (state) => (rule: Rule) => {
  //       switch (rule) {
  //         case 'FT':
  //           return state?.bigConstituencies
  //         case 'KV':
  //         case 'LV':
  //           return state?.municipalities
  //         case 'RV':
  //           return state?.regions
  //         case 'EP':
  //           return [EP_GROUP]
  //       }
  //     },
  //   },

  function getCustomLogoUrl(query: LocationQuery): string | undefined {
    const config = useRuntimeConfig()
    const logoUrlUnvalidated = query.logoUrl
    if (!logoUrlUnvalidated) return
    const logoUrlSchema = z
      .string()
      .url()
      .startsWith(`https://${config.public.site.url}/`)
    const logoUrl = logoUrlSchema.safeParse(logoUrlUnvalidated)
    if (logoUrl.success) {
      return logoUrl.data
    }
  }

  function getAllowedIdsFromQueryParameters(query: LocationQuery) {
    const routeAllowedIdsFromQuery = query.allowedIds
    const routeAllowedIds = routeAllowedIdsFromQuery?.toString().includes(',')
      ? routeAllowedIdsFromQuery.toString().split(',')
      : routeAllowedIdsFromQuery
    const allowedIds = routeAllowedIds
      ? new Set(
          typeof routeAllowedIds == 'string'
            ? [parseInt(routeAllowedIds)]
            : routeAllowedIds.map((id) => parseInt(id?.toString() ?? ''))
        )
      : new Set<number>()
    return allowedIds
  }
})
